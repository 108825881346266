import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { RouterLink } from '@angular/router';

@Component({
    templateUrl: './forbidden-page.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatIcon, MatButton, RouterLink],
})
export class ForbiddenPageComponent {}
